import { RoutingInterface } from './RoutingInterface';

export const useRoutingStore = (): RoutingInterface => ({
  routing: {
    knowledgeBase: {
      active: true,
      oldActive: false,
      id: '',
      oldId: '',
      name: '',
      type: '',
      backendId: '',
      order: 0,
      oldOrder: 0,
    },
    form: {
      active: false,
      oldActive: false,
      script: '',
      oldScript: '',
      backendId: '',
      order: 0,
      oldOrder: 0,
    },
    meetingSchedule: {
      active: false,
      oldActive: false,
      script: '',
      oldScript: '',
      backendId: '',
      order: 0,
      oldOrder: 0,
    },
    whatsApp: {
      active: true,
      oldActive: false,
      phoneId: '',
      oldPhoneId: '',
      customPhoneId: '+',
      backendId: '',
      order: 0,
      oldOrder: 0,
      scheduleGroup: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        type: '',
      },
      estimatedWaiting: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        maxTime: 0,
        oldMaxTime: 0,
        type: '',
      },
    },
    chat: {
      active: true,
      oldActive: false,
      id: '',
      oldId: '',
      name: '',
      type: '',
      backendId: '',
      order: 0,
      oldOrder: 0,
      scheduleGroup: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        type: '',
      },
      estimatedWaiting: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        maxTime: 0,
        oldMaxTime: 0,
        type: '',
      },
    },
    facebookChat: {
      active: true,
      oldActive: false,
      pageId: '',
      oldPageId: '',
      name: '',
      type: '',
      backendId: '',
      order: 0,
      oldOrder: 0,
      scheduleGroup: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        type: '',
      },
      estimatedWaiting: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        maxTime: 0,
        oldMaxTime: 0,
        type: '',
      },
    },
    callUs: {
      active: false,
      oldActive: false,
      name: '',
      ownerType: null,
      ownerID: null,
      type: '',
      mobilePhone: '',
      oldMobilePhone: '',
      phone: '',
      backendId: '',
      order: 0,
      oldOrder: 0,
      scheduleGroup: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        type: '',
      },
      estimatedWaiting: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        maxTime: 0,
        oldMaxTime: 0,
        type: '',
      },
    },
    video: {
      active: false,
      oldActive: false,
      name: '',
      ownerType: null,
      ownerID: null,
      type: '',
      mobilePhone: '',
      oldMobilePhone: '',
      phone: '',
      backendId: '',
      order: 0,
      oldOrder: 0,
      scheduleGroup: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        type: '',
      },
      estimatedWaiting: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        maxTime: 0,
        oldMaxTime: 0,
        type: '',
      },
    },
    immediateCallback: {
      active: true,
      oldActive: false,
      id: '',
      oldId: '',
      scriptId: '',
      oldScriptId: '',
      name: '',
      type: '',
      countryValue: '',
      oldCountryValue: '',
      backendId: '',
      order: 0,
      oldOrder: 0,
      scheduleGroup: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        type: '',
      },
      estimatedWaiting: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        maxTime: 0,
        oldMaxTime: 0,
        type: '',
      },
    },
    scheduleCallback: {
      active: true,
      oldActive: false,
      id: '',
      oldId: '',
      scriptId: '',
      oldScriptId: '',
      name: '',
      type: '',
      countryName: '',
      countryValue: '',
      oldCountryValue: '',
      scheduleGroupId: '',
      oldScheduleGroupId: '',
      scheduleGroupName: '',
      backendId: '',
      order: 0,
      oldOrder: 0,
    },
    scheduleVideo: {
      active: false,
      oldActive: false,
      name: '',
      ownerType: null,
      ownerID: null,
      phone: '',
      slotDuration: 15,
      oldSlotDuration: 15,
      availableSlots: 1,
      oldAvailableSlots: 1,
      type: '',
      backendId: '',
      order: 0,
      oldOrder: 0,
      scheduleGroupId: '',
      oldScheduleGroupId: '',
    },
  },
});
