import { DesignStyleInterface } from './designStyleInterface';

export const useDesignStyleStore = (): DesignStyleInterface => ({
  designAndStyle: {
    colors: {
      desktop: {
        themeColor: '#3d5af5',
        widgetButton: {
          background: '#3d5af5',
          text: '#FFFFFF',
          hover: '#3751dd',
        },
        button: {
          background: '#3d5af5',
          text: '#FFFFFF',
          hover: '#3751dd',
        },
        icon: {
          text: '#3d5af5',
          area: '#e8ebfe',
        },
        font: {
          headLineText: '#1d2b76',
          bodyText: '#606588',
          headerText: '#eff2fe',
        },
        chat: {
          consultantText: '#171754',
          consultantBackground: '#F7F6FA',
          userText: '#ffffff',
          userBackground: '#3d5af5',
        },
        headsUp: {
          text: '#000000',
        },
      },
      mobile: {
        themeColor: '#3d5af5',
        widgetButton: {
          background: '#3d5af5',
          text: '#FFFFFF',
          hover: '#3751dd',
        },
        button: {
          background: '#3d5af5',
          text: '#FFFFFF',
          hover: '#3751dd',
        },
        icon: {
          text: '#3d5af5',
          area: '#e8ebfe',
        },
        font: {
          headLineText: '#1d2b76',
          bodyText: '#606588',
          headerText: '#eff2fe',
        },
        chat: {
          consultantText: '#171754',
          consultantBackground: '#F7F6FA',
          userText: '#ffffff',
          userBackground: '#3d5af5',
        },
        headsUp: {
          text: '#000000',
        },
      },
      localThemeColor: '#3d5af5',
    },
    icon: {
      type: 'outline',
      value: 'icon1'
    },
    logo: {
      type: 'background',
      status: 'off',
      url: '',
      key: '',
    },
    avatars: {
      status: 'on',
      avatar1: 'icon1',
      avatar2: 'icon2',
      avatar3: 'icon3',
    },
    files: {
      icon: [],
      avatars: [],
      logo: [],
      pattern: [],
    },
    position: {
      alignment: 'right',
      spacing: {
        bottom: 0,
        side: 0,
      },
      headsUpPlacement: 'Above',
    },
    headsUpType: 'text',
    headerType: 'small',
    font: 'Roboto',
    corner: '8px',
    headerBackground: {
      type: 'basic',
      pattern: {
        type: 'circles',
        customUrl: '',
        customUrlKey: '',
      },
      gradient: {
        type: 'linear',
        color: '#171754',
      },
    },
  },
});
