import { GeneralInterface } from './GeneralInterface';

export const useGeneralStore = (): GeneralInterface => ({
  general: {
    about: {
      active: true,
      lastEdit: '',
      created: '',
      priority: 'A',
    },
    name: {
      widgetName: '',
      oldWidgetName: '',
      widgetDescription: '',
    },
    language: {
      selectedLanguageValue: 'en',
    },
    customAttributes:[],
    additionalSettings: {
      singleChannelMode: false,
      chatTranscript: false,
      defaultVisitorBlur: false,
      defaultAgentBlur: false,
      chooseWebMessengerDeployment: {
        active: false,
        deploymentId: null,
      },
      channelsOrder: [
        'chatsWrapper',
        'knowledgeBase',
        'chat',
        'whatsApp',
        'facebookChat',
        'callUs',
        'video',
        'callback',
        'form',
        'meetingSchedule',
        'scheduleVideo',
      ],
    },
    aiHeadsUpTexts: false,
  },
});
